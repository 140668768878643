<template>
  <div class="login-page-width">
    <div id="recaptcha-container"></div>
    <br />
    <!--begin::Content header-->
    <!--    <div-->
    <!--      class="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10"-->
    <!--    >-->
    <!--      <span class="font-weight-bold font-size-3 text-dark-60">-->
    <!--        Don't have an account yet?-->
    <!--      </span>-->
    <!--      <router-link-->
    <!--        class="font-weight-bold font-size-3 ml-2"-->
    <!--        :to="{ name: 'register' }"-->
    <!--      >-->
    <!--        Sign Up!-->
    <!--      </router-link>-->
    <!--    </div>-->
    <!--end::Content header-->

    <!--begin::Signin-->

    <div class="login-form login-signin" v-if="!isPhoneVerified">
      <div class="text-center mb-10 mb-lg-10">
        <h3 class="font-size-h1 font-weight-bold">Sign In</h3>
        <p class="text-muted font-weight-semi-bold">
          Enter your email and password
        </p>
      </div>

      <!--begin::Form-->
      <b-form class="form" @submit.stop.prevent="onSubmit">
        <!--        <div role="alert" class="alert alert-info">-->
        <!--          <div class="alert-text">-->
        <!--            Use account <strong>sales@smartservetech.com</strong> and password-->
        <!--            <strong>123456</strong> to continue.-->
        <!--          </div>-->
        <!--        </div>-->

        <div
          role="alert"
          v-bind:class="{ show: errors.length }"
          class="alert fade alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ error }}
          </div>
        </div>

        <b-form-group
          id="example-input-group-1"
          label=""
          label-for="example-input-1"
        >
          <b-form-input
            class="form-control form-control-solid"
            id="example-input-1"
            name="example-input-1"
            v-model="$v.form.email.$model"
            :state="validateState('email')"
            aria-describedby="input-1-live-feedback"
          ></b-form-input>

          <b-form-invalid-feedback id="input-1-live-feedback">
            Email is required and a valid email address.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          id="example-input-group-2"
          label=""
          label-for="example-input-2"
        >
          <b-form-input
            class="form-control form-control-solid"
            type="password"
            id="example-input-2"
            name="example-input-2"
            v-model="$v.form.password.$model"
            :state="validateState('password')"
            aria-describedby="input-2-live-feedback"
          ></b-form-input>

          <b-form-invalid-feedback id="input-2-live-feedback">
            Password is required.
          </b-form-invalid-feedback>
        </b-form-group>

        <!--begin::Action-->
        <div
          class="form-group d-flex flex-wrap justify-content-between align-items-center"
        >
          <a
            href="#"
            class="text-dark-60 text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
          >
            <!--            Forgot Password ?-->
          </a>
          <button
            ref="kt_login_signin_submit"
            class="btn btn-primary my-3 font-size-3"
          >
            Sign In
          </button>
        </div>
        <!--end::Action-->
      </b-form>
      <!--end::Form-->
    </div>

    <div
      class="login-form login-signin login-code-verification"
      v-if="isPhoneVerified"
    >
      <div class="text-center mb-10 mb-lg-10">
        <h3 class="font-size-h1 font-weight-bold">Sign In</h3>
        <p class="text-muted font-weight-semi-bold">
          Please enter verification code
        </p>
      </div>
      <p class="text-muted font-weight-semi-bold">2-Step Verification</p>
      <p class="text-muted font-weight-semi-bold">
        Enter the verification code sent to your phone ending in xxxxxx xxx
        {{ lastThreeDigits }}
      </p>
      <div class="form">
        <div
          role="alert"
          v-bind:class="{ show: errors.length }"
          class="alert fade alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ error }}
          </div>
        </div>

        <b-form-group
          class="d-none"
          id="example-input-group-1"
          label="Phone Number:"
          label-for="example-input-1"
        >
          <b-form-input
            class="form-control form-control-solid"
            id="example-input-1"
            name="example-input-1"
            v-model="phone"
            :disabled="true"
            aria-describedby="input-1-live-feedback"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="example-input-group-2"
          label="Enter 2-Step Verification code:"
          label-for="example-input-2"
        >
          <div class="d-flex justify-content-start align-items-center">
            <b-form-input
              class="form-control form-control-solid"
              type="number"
              v-model="code"
              aria-describedby="input-2-live-feedback"
            ></b-form-input>
            <button
              style="margin-left: 10px;"
              ref="kt_verify_otp"
              class="btn btn-primary my-3 font-size-3"
              @click="verifyOtp"
            >
              Submit
            </button>
          </div>
        </b-form-group>
        <!--begin::Action-->
        <div
          class="form-group w-100 d-flex flex-wrap justify-content-between align-items-center"
        >
          <p class="mb-0 text-muted font-weight-semi-bold">
            I did not receive a code
          </p>
          <button
            ref="kt_send_otp"
            class="btn btn-primary my-3 font-size-3"
            @click="sendOtp"
          >
            Resend
          </button>
        </div>
        <!--end::Action-->
      </div>
    </div>
    <!--end::Signin-->
  </div>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
import { mapState } from "vuex";
import { LOGIN, LOGOUT } from "@/core/services/store/auth.module";

import { validationMixin } from "vuelidate";
import { email, minLength, required } from "vuelidate/lib/validators";
import firebase from "firebase";
import axios from "axios";
import helpers from "../../../helpers";

export default {
  mixins: [validationMixin],
  name: "login",
  data() {
    return {
      isPhoneVerified: false,
      phoneVerified: false,
      resendOtp: false,
      phone: "",
      code: "",
      appVerifier: "",
      lastThreeDigits: "",
      // Remove this dummy login info
      form: {
        email: "sales@smartservetech.com",
        password: "123456"
      }
    };
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(3)
      }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        email: null,
        password: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    sendOtpCode() {},
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const email = this.$v.form.email.$model;
      const password = this.$v.form.password.$model;

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      setTimeout(() => {
        // send login request
        this.$store
          .dispatch(LOGIN, { email, password })
          // go to which page after successfully login
          .then(() => {
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
            let user = JSON.parse(localStorage.getItem("currentUser"));
            this.phone = user ? user.mobile_number : "";
            let phoneNumber = this.phone;
            this.lastThreeDigits = phoneNumber.slice(phoneNumber.length - 3);
            this.sendOtp();
          })
          .catch();
      }, 2000);
    },
    sendOtp() {
      let vm = this;
      //
      //let countryCode = "+92"; //india
      // let phoneNumber = countryCode + this.phone;
      const operators = ["+"];
      const isOperator = operators.includes(this.phone);
      let phoneNumber = this.phone;
      if (!isOperator) {
        phoneNumber = "+" + this.phone;
      }

      let appVerifier = this.appVerifier;
      //
      firebase
        .auth()
        .signInWithPhoneNumber(phoneNumber, appVerifier)
        .then(function(confirmationResult) {
          vm.isPhoneVerified = true;
          vm.resendOtp = true;
          vm.$bvToast.toast(
            "Two Factor Authentication code send your phone number please verify!",
            {
              title: "Success",
              variant: "success",
              solid: true,
              toaster: "b-toaster-top-center"
            }
          );

          window.confirmationResult = confirmationResult;
          //
        })
        .catch(function(error) {
          vm.errorMessage(error.message);
        });
    },
    errorMessage(message) {
      this.$bvToast.toast(message, {
        title: "Error",
        variant: "danger",
        solid: true,
        toaster: "b-toaster-top-center"
      });
    },
    initReCaptcha() {
      setTimeout(() => {
        // let vm = this
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
          "recaptcha-container",
          {
            size: "invisible",
            // eslint-disable-next-line no-unused-vars
            callback: function(response) {},
            "expired-callback": function() {
              // Response expired. Ask user to solve reCAPTCHA again.
              // ...
            }
          }
        );
        //
        this.appVerifier = window.recaptchaVerifier;
      }, 1000);
    },
    verifyOtp() {
      const submitButton = this.$refs["kt_verify_otp"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      let code = this.code;
      let vm = this;

      window.confirmationResult
        .confirm(code)
        // eslint-disable-next-line no-unused-vars
        .then(function(result) {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          localStorage.setItem("isPhoneVerified", "true");
          let User = JSON.parse(localStorage.getItem("currentUser"));
          if (User.roles[0].title === "System Administrator") {
            vm.$router.push({ name: "dashboard" });
          } else if (User.roles[0].title === "Business Owner") {
            axios
              .get(
                "https://api.connectsmartserve.com/api/v1/consumer/stripe/restaurant/account/connect/retrieve/" +
                  User.restaurant.uuid
              )
              .then(response => {
                if (
                  response.data.data.charges_enabled &&
                  response.data.data.payouts_enabled
                ) {
                  vm.$bvToast.toast("user login successfully!", {
                    title: "Success",
                    variant: "success",
                    solid: true,
                    toaster: "b-toaster-top-center"
                  });
                  vm.$router.push({ name: "RestaurantDashboard" });
                } else {
                  vm.$bvToast.toast(
                    "Information required! Please login to your stripe account.",
                    {
                      title: "Error",
                      variant: "danger",
                      solid: true,
                      toaster: "b-toaster-top-center"
                    }
                  );
                }
              })
              .catch(err => {
                if (err.response.data.status === 400) {
                  helpers.errorOther(this, err.response.data);
                }
                if (err.response.data.status === 422) {
                  helpers.validationError(this, err.response.data.errors);
                }
                if (err.response.status === 404) {
                  helpers.errorMessage(this, "url not found");
                }
                if (err.response.status === 500) {
                  helpers.serverError(this);
                }
              });
          } else {
            localStorage.setItem(
              "restaurantDetails",
              JSON.stringify(User.restaurant)
            );
            vm.$router.push({ name: "restaurant_setting" });
          }
        })
        .catch(function(error) {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          vm.errorMessage(error.message);
        });
    }
  },

  created() {
    this.initReCaptcha();
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    })
  }
};
</script>
<style scoped>
.login-page-width {
  width: 70%;
}
.login-code-verification {
  width: 70%;
  margin: 0 auto;
}
</style>
